<template>
  <PrimaryMenu />

  <Header title="Szolgáltatásaink / Áraink" />

  <SectionServices />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionServices from "@/components/layout/sections/SectionServices";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";

export default {
  name: "Services",
  title: "Szolgáltatásaink / Áraink",
  components: {
    PrimaryMenu,
    Header,
    SectionServices,
    SectionContact,
    Footer,
  },
};
</script>

<style scoped lang="scss"></style>
